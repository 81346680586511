<div class="right-col-title" trans>Latest Articles & News about {{personName}}</div>
    <div class="articles" *ngIf="newsArticles">
        <div class="article" *ngFor="let article of newsArticles">
            <img [src]="article?.meta?.image">
            <div class="media-body">
                <a [routerLink]="article.article_url" class="title">{{article.title}}</a>
                <p class="body">{{article.body | slice:0:300}}...</p>
                <div class="meta">
                    <ng-container *ngIf="article?.meta?.source">
                        <span class="source"> {{article.meta.source}}</span>
                    </ng-container>
                    <span>{{article.created_at | formattedDate}}</span>
                </div>
            </div>
        </div>
    </div>

