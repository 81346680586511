import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NewsService} from '../news.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NewsArticle} from '../../../models/news-article';
import {DomSanitizer} from '@angular/platform-browser';
import { slugifyString } from '@common/core/utils/slugify-string';
slugifyString


@Component({
    selector: 'news-article',
    templateUrl: './news-article.component.html',
    styleUrls: ['./news-article.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsArticleComponent implements OnInit {
    public article$: Subject<NewsArticle> = new Subject();
    public loading$: Subject<boolean> = new Subject();
    public sidebarArticles$: Subject<NewsArticle[]> = new Subject();

    constructor(
        private news: NewsService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        this.bindToRouteParams();
    }

    public trustHtml(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    
    private getSidebarArticles() {
        return this.news.getAll({perPage: 10}).subscribe(response => {
            //don't include the current article in the sidebar
            const articles = response.pagination.data.filter(article => article.id !== +this.route.snapshot.params.id);
            //modify the article_url to be "/news/{news_article_id}" if there is no primary_review_id else set it to "/titles/${title_id}/${slugifyString(name)}"
            articles.forEach(article => {
                if (article.primary_title) {
                    article.article_url = `/titles/${article.primary_title.id}/${slugifyString(article.primary_title.name)}`;
                } else {
                    article.article_url = `/news/${article.id}`;
                }
                console.log( article);
            }
            );
            
            this.sidebarArticles$.next(articles);
            //this.sidebarArticles$.next(response.pagination.data);
        });
    }
    
    private bindToRouteParams() {
        this.route.params.subscribe(params => {
            this.loading$.next(true);
            this.news.get(+params.id)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                    this.article$.next(response.article);
                });
            this.getSidebarArticles();
        });
    }
}
