import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'primary-review',
  templateUrl: './primary-review.component.html',
  styleUrls: ['./primary-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PrimaryReviewComponent implements OnInit {
  //gets input of a "review" object from the parent component
  @Input() review: any;
  hidden: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  
  }

  public trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
}

  public toggleHidden() {
    this.hidden = !this.hidden;
    // when review is hidden, scroll to div with class primary-review
    if (this.hidden) {
      const element = document.getElementsByClassName('review-title')[0] as HTMLElement | null;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }

}
