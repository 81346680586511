import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'title-related-news-articles',
  templateUrl: './title-related-news-articles.component.html',
  styleUrls: ['./title-related-news-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TitleRelatedNewsArticlesComponent implements OnInit {

  @Input() newsArticles: any[];
  constructor() { }

  ngOnInit(): void {
  }

}
