import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import {slugifyString} from '@common/core/utils/slugify-string';
@Component({
  selector: 'related-news',
  templateUrl: './related-news.component.html',
  styleUrls: ['./related-news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class RelatedNewsComponent implements OnInit {
  @Input() personName: any;
  @Input() newsArticles: any[];
  constructor() { }

  ngOnInit(): void {
    //loop through news articles and set the article_url to "/news/{news_article_id}" if there is no primary_review_id else set it to "/titles/${title_id}/${slugifyString(name)}"
    this.newsArticles.forEach(article => {
      if (article.primary_review_id) {
        article.article_url = `/titles/${article.title_id}/${slugifyString(article.name)}`;
      } else {
        article.article_url = `/news/${article.news_article_id}`;
      }
    }
    );
  }

}
